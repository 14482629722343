import React from "react";
import Navbar from "../Navbar/Navbar";
import FadeIn from "react-fade-in";
import "./AboutUs.css";
import ContactUs from "../ContactUs/ContactUs";

const AboutUs = () => {

    return (
        <div>
            <Navbar />
            <FadeIn>
            <div className="about-us" >
                <h1 className="about-header">About Us</h1>
                <div className="row about-text-container">
                    <p className="about-text">We help e-commerce brands and retailers scale rapidly, build innovatively, and <span className="about-red">grow holistically.</span></p>
                </div>
                <div className="row about-text-container-2">
                    <p className="about-text">Headquarters in Brisbane, Australia.</p>
                    <p className="about-text" style={{width: '75%'}}>200+ full-time staff globally. Major offices in Malaysia, Sri Lanka, Indonesia and India.</p>
                </div>
            </div>
            <ContactUs />
            </FadeIn>
        </div>
    )
};

export default AboutUs;