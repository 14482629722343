import React from "react";
import Navbar from "../Navbar/Navbar";
import ContactUs from "../ContactUs/ContactUs";
import FadeIn from "react-fade-in";
import "./Process.css";

const Process = () => {
    return (
        <div>
            <Navbar />
            <FadeIn>
            <div className="our-process-container">
                <h1 className="process-header">Our Process</h1>
                <div className="process-image-container">
                    <img 
                    src="../../sourcemate--1-removebgpreview-12@2x.png"
                    alt="SourceMate"
                    className="process-image"
                    />
                </div>

                <div className="row our-process-cards">
                    <div className="col-xxl-5 col-xl-5 col-md-12 col-sm-12">
                        <div className="our-process-card">
                            <span className="our-process-no-container"><span className="our-process-no">1</span></span>
                            <h1 className="our-process-title">Research analysis</h1>
                            <h1 className="our-process-title">& Presentation</h1>
                            <p className="our-process-time">Time : 4 weeks</p>
                            <p className="our-process-text">We commence by learning about your business, brand, goals and your products. We will take a deep dive into SKUs, 
                            improvements, current, past issues and growth trajectory. We research your exact requirements and recommend which of our factories will work best 
                            for you. With our global team across 5 countries at the helm, we speak their language and business culture. We ensure they meet strict standards, 
                            compliance, ethical responsibilities, diversity inclusion, quality control & 
                            intellectual property rights.</p>
                        </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-md-12 col-sm-12 process-arrow-container">
                        {
                            window.innerWidth>=1400 ?
                            <img 
                                src="../../arrow.png"
                                alt="arrow"
                                className="process-arrow"
                            />
                            :
                            null
                        }
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-md-12 col-sm-12">
                        <div className="our-process-card">
                        <span className="our-process-no-container"><span className="our-process-no">2</span></span>
                            <h1 className="our-process-title our-title-2">Product Development Process</h1>
                            <h1 className="our-process-title">and Sampling</h1>
                            <p className="our-process-time">Time : 1-4 months</p>
                            <p className="our-process-text our-text-2">We work together collaboratively to build your product from the ground level up. Get samples for approval before 
                            production even starts. Our production team manages process – driven sampling from start to finish, reviewing samples 
                            in house so you have complete confidence you’re ready to move into production</p>
                        </div>
                    </div>
                </div>
                <div className="row our-process-cards">
                    <div className="col-xxl-5 col-xl-5 col-md-12 col-sm-12">
                        <div className="our-process-card">
                            <span className="our-process-no-container"><span className="our-process-no">3</span></span>
                            <h1 className="our-process-title our-title-2">Production and Quality</h1>
                            <h1 className="our-process-title">Management</h1>
                            <p className="our-process-time our-time-3">Time : 4-7 weeks</p>
                            <p className="our-process-text our-text-3">We’ll handle the ordering process, liaising with manufacturing partners to ensure 
                            quality outcome.</p>
                        </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-md-12 col-sm-12 process-arrow-container">
                        {
                            window.innerWidth>=1400 ?
                            <img 
                                src="../../arrow.png"
                                alt="arrow"
                                className="process-arrow"
                            />
                            :
                            null
                        }
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-md-12 col-sm-12">
                        <div className="our-process-card">
                        <span className="our-process-no-container"><span className="our-process-no">4</span></span>
                            <h1 className="our-process-title our-title-2">Shipping and Logistics</h1>
                            <p className="our-process-time our-time-3">Time : 2-5 weeks (multi-location)</p>
                            <p className="our-process-text our-text-2 our-text-3">Our team handle all freight, clearance, importing & delivery after your good are produced & 
                            quality checked. Packaging, logistics, fulfilment – we’re right there with you all the way, working 
                            as a trusted extension of your business</p>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUs />
            </FadeIn>
        </div>
    );
};

export default Process;