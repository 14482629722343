import React, {useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from "framer-motion"
import './Navbar.css';

const Navbar = () => {
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(window.innerWidth<1024);
    const animateFrom = {opacity: 0, y: -40};
    const animateTo = {opacity: 1, y:0};

    return (
        <div className='navbar'>
            <div className='navbar-left' onClick={() => navigate('/')}>
                <img
                    className="navbar-logo"
                    alt="SourceMate"
                    src="../../sourcemate--1-removebgpreview-3@2x.png"
                />
            </div>
            <div className='hamburger-menu'>
                {toggle ? <i className="fa-solid fa-bars hamburger-icon" onClick={() => setToggle(!toggle)}></i> : <i className="fa-solid fa-x hamburger-icon" onClick={() => setToggle(!toggle)}></i>}
                
            </div>
            {
            !toggle ?
            <div className='navbar-right'>
                
                <div className='navbar-item'>
                    <motion.div
                    initial={animateFrom}
                    animate={animateTo}
                    className="motion-links">
                    <Link to="/" className="navbar-link">
                        Home
                    </Link>
                    </motion.div>
                </div>
                <div className='navbar-item'>
                    <motion.div
                    initial={animateFrom}
                    animate={animateTo}
                    className="motion-links">
                    <Link to="/about-us" className="navbar-link">
                        About Us
                    </Link>
                    </motion.div>
                </div>
                <div className='navbar-item'>
                    <motion.div
                    initial={animateFrom}
                    animate={animateTo}
                    className="motion-links">
                    <Link to="/our-process" className="navbar-link">
                        Process
                    </Link>
                    </motion.div>
                </div>
                <div className='navbar-item'>
                    <motion.div
                    initial={animateFrom}
                    animate={animateTo}
                    className="motion-links">
                    <a className="navbar-link" href="#contact-us-row">
                        Contact Us
                    </a>
                    </motion.div>
                </div>
                
            </div>
            :
                null
            }
        </div>
    );
};

export default Navbar;