import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import {useAlert} from 'react-alert'
import './ContactUs.css';

const ContactUs = () => {
    const navigate = useNavigate();
    const alert = useAlert();
    const [contact, setContact] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        based: "",
        message: ""
    });

    const onSend = async(event) => {
        event.preventDefault();
        emailjs.send('service_iurrwbs', 'template_hzpicdg', contact, 'MEc9nK4C--dC_xE8C')
            .then((data) => {
                setContact({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    companyName: "",
                    based: "",
                    message: ""
                });
                alert.show('Message sent successfully!');
            })
            .catch((err) => {
                setContact({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    companyName: "",
                    based: "",
                    message: ""
                });
            alert.show(err.message);
            });
    }

    return (
        <div className="contact-us-row" id="contact-us-row">
            <div className="container-fluid">
                <div className="row footer-container">
                    <div className="col-xxl-3 col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="footer-logo-container" onClick = {() => navigate('/')}>
                            <img 
                                className="footer-logo"
                                alt="SourceMate"
                                src="../../sourcemate--1-removebgpreview-1@2x.png"
                            />
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="footer-middle-heading">
                            <h1>Get in Touch</h1>
                        </div>
                        <div className="footer-middle-text">
                            <p>Connect with us to Know more</p>
                        </div>
                        <div className="footer-middle-info">
                            <p>Contact Information</p>
                            <p>SourceMate</p>
                            <p>200 Adelaide Street</p>
                            <p>Brisbane 4000</p>
                            <p>Ph no : 07 3266 7844</p>
                            <p>Email-id : info@sourcemate.com.au</p>
                        </div>
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg12 col-sm-12 col-xs-12">
                        <div className="footer-right-form">
                            <form method="post" onSubmit={onSend}>
                                <div className="row">
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-7">
                                        <input 
                                        placeholder="First Name"
                                        className="footer-form-element-1"
                                        type="text"
                                        required="required"
                                        value={contact.firstName}
                                        onChange={(e) => setContact({...contact, firstName: e.target.value})}
                                        />
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-7">
                                        <input 
                                        placeholder="Last Name"
                                        className="footer-form-element-1"
                                        type="text"
                                        required="required"
                                        value={contact.lastName}
                                        onChange={(e) => setContact({...contact, lastName: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12">
                                        <input 
                                        placeholder="Company Name"
                                        className="footer-form-element-2"
                                        type="text"
                                        required="required"
                                        value={contact.companyName}
                                        onChange={(e) => setContact({...contact, companyName: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                <div className="col-xxl-12 col-xl-12">
                                        <input 
                                        type="number"
                                        placeholder="Phone Number"
                                        className="footer-form-element-2"
                                        required="required"
                                        value={contact.phone}
                                        onChange={(e) => setContact({...contact, phone: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12">
                                        <input 
                                        type="email"
                                        placeholder="Email address"
                                        className="footer-form-element-2"
                                        required="required"
                                        value={contact.email}
                                        onChange={(e) => setContact({...contact, email: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12">
                                        <input 
                                        placeholder="Where are you based?"
                                        className="footer-form-element-2 input-back"
                                        type="text"
                                        required="required"
                                        value={contact.based}
                                        onChange={(e) => setContact({...contact, based: e.target.value})}
                                        />
                                        {/* <p className="arrows">&lt;&gt;</p> */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12">
                                        <textarea 
                                        rows="5"
                                        className="footer-form-element-3"
                                        type="text"
                                        required="required"
                                        value={contact.message}
                                        onChange={(e) => setContact({...contact, message: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-3">
                                        <button className="footer-form-button" type="submit">
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row footer-copyright">
                    <img 
                        src="../../nicepng-copyrightsymbolpng-247730-1@2x.png"
                        className="copyright-logo"
                        alt="copyright"
                    />
                    &nbsp;
                    <p className="">All rights reserved 2023</p>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;