import React from "react";
import Navbar from "../Navbar/Navbar";
import ContactUs from "../ContactUs/ContactUs";
import FadeIn from "react-fade-in";
import './Home.css';

const Home = () => {
    return (
        <div>
        <Navbar />
        <FadeIn>
        <div className="container-fluid home-container">
            <div className="row home-row1">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <h1 className="home-h1">
                        Build Quality Products, <br>
                        </br>Faster
                    </h1>
                    <br></br>
                    <p className="home-p">
                        Be the first to deliver innovative products to market.<br></br>  Access trusted network of manufacturers and factories.
                    </p>
                    <a href="#what-we-do">
                    <button className="home-explore">
                        Explore Us
                    </button>
                    </a>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <img
                    src="../../adobestock-307892428-1-1@2x.png"
                    alt="picture"
                    className="home-img"
                    />
                </div>
            </div>
            <div className="row home-row2" id="what-we-do">
                <div className="row home2-p">
                    <p>What we do.</p>
                </div>
                <div className="row home2-block">
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="home-card">
                            <div className="home-card-image-container">
                            <img 
                                src="../../d4fea038acc946a68b54be1aca302f46-product-developmentpng@2x.png"
                                className="home-card-image"
                                alt="image"
                            />
                            </div>
                            <h4 className="home-card-header">Product<br></br>Development</h4>
                            <br>
                            </br>
                            <p className="home-card-text">Accelerate your products to market with our highly skilled and experienced manufacturing partners to enhance your brand and dominate your category.</p>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="home-card">
                            <div className="home-card-image-container">
                            <img 
                                src="../../4745023d474f441a8ca899f6f5b83bb0-product-sourcingpng@2x.png"
                                className="home-card-image"
                                alt="image"
                            />
                            </div>
                            <h4 className="home-card-header">Product Sourcing</h4>
                            <br>
                            </br>
                            <p className="home-card-text">We’ve built a trusted, experienced and proven manufacturing network to create your next product.</p>
                            <p className="home-card-text">We can cover unique features, pricing point or category. </p>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                        <div className="home-card">
                            <div className="home-card-image-container">
                            <img 
                                src="../../7dd7efbc0a3740068227367ea91800f1-manufacturingpng@2x.png"
                                className="home-card-image"
                                alt="image"
                            />
                            </div>
                            <h4 className="home-card-header">Manufacturing</h4>
                            <br>
                            </br>
                            <p className="home-card-text">Whether you need to modify sample prototypes, handle quality control, negotiate pricing, terms and due diligence.</p>
                            <p className="home-card-text">Now you can access and build a team of specialist to manufacture the best version of your product as possible.</p>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="home-card">
                            <div className="home-card-image-container">
                            <img 
                                src="../../40f0f4e1a8bd481da203bbd13035ca17-importingpng@2x.png"
                                className="home-card-image"
                                alt="image"
                            />
                            </div>
                            <h4 className="home-card-header">Importing</h4>
                            <br>
                            </br>
                            <p className="home-card-text">Now you can access and build a team of specialist to manufacture the best version of your product as possible.</p>
                            
                        </div>
                    </div>
                        
                    </div>
                </div>
            </div>

            <div className="home-row3">
                <p className="banner-p1">
                    Great vision without great people <br></br>to back you never goes far.
                </p>
                <br></br>
                <p className="banner-p2">
                    We're here for you.
                </p>
                <a href="#contact-us-row">
                    <button className="banner-button">
                        Get in touch today
                    </button>
                </a>
            </div>

            <div className="home-row4">
                <h1 className="home-process">Our Process</h1>
                <div className="row container home-process-container">
                    <div className="col-xxl-5 col-xl-5 col-md-10 col-xs-12 col-sm-12">
                        <div className="home-process-card">
                            <span className="home-process-no-container"><span className="home-process-no">1</span></span>
                            <p className="home-process-text">Research analysis & Presentation</p>
                        </div>  
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-md-10 col-xs-12 col-sm-12">
                        <div className="home-process-card">
                            <span className="home-process-no-container"><span className="home-process-no">2</span></span>
                            <p className="home-process-text">Product Development Process and Sampling</p>
                        </div>  
                    </div>
                </div>
                <div className="row container home-process-container process-row-2">
                    <div className="col-xxl-5 col-xl-5 col-md-10 col-xs-12 col-sm-12">
                        <div className="home-process-card">
                            <span className="home-process-no-container"><span className="home-process-no">3</span></span>
                            <p className="home-process-text">Production and Quality Management</p>
                        </div>  
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-md-10 col-xs-12 col-sm-12">
                        <div className="home-process-card">
                            <span className="home-process-no-container"><span className="home-process-no">4</span></span>
                            <center><p className="home-process-text">Shipping and <br></br>Logistics</p></center>
                        </div>  
                    </div>
                </div>
                
                   
            </div>

            <div className="row home-row5">
                <div className="product-container">
                    <h1 className="product-h1">
                        Our Product Categories.
                    </h1>
                    <div className="row product-cols">
                    <div className="col-xxl-4 col-xl-4 col-md-12 col-xs-12 col-sm-12">
                        <div className="product-card-2">
                            <p className="product-card-heading">Apparel</p>
                            <br></br>
                            <p className="product-card-content">The Apparel category requires keen eye to 
                            detail and understanding to ensure you achieve the best. Our manufacturing partners has international experience in 
                            producing apparels of the highest quality.</p>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-md-12 col-xs-12 col-sm-12">
                        <div className="product-card-2">
                            <p className="product-card-heading">Consumer Durables</p>
                            <br></br>
                            <p className="product-card-content">
                            Just about everything you need to build cabinets, case goods and tables, except the wood. We will also cover buttons, caps, dyes, machine, nuts, 
                            bolts and dyes.</p>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-md-12 col-xs-12 col-sm-12">
                        <div className="product-card-2">
                            <p className="product-card-heading">Homewares</p>
                            <br></br>
                            <p className="product-card-content">The Homewares category needs careful 
                            attention in design and materials to ensure quality release g
                            iven the highly competitive market. Our team of homewares specialists and industrial designers will guide through the product development and end product.</p>
                        </div>
                    </div>
                    </div>
                    <div className="row product-cols">
                        <div className="col-xxl-4 col-xl-4 col-md-12 col-xs-12 col-sm-12">
                            <div className="product-card-2">
                                <p className="product-card-heading">Medical Equipment</p>
                                <br></br>
                                <p className="product-card-content">An ageing population and demand for better medical equipments has driven Australian
                                manufactures to serve the market with
                                higher quality products.</p>
                                <p className="product-card-content">
                                Our experience will give you the edge in
                                bringing modern equipment to market faster.
                                </p>
                            </div>
                        </div>
    
                        <div className="col-xxl-4 col-xl-4 col-md-12 col-xs-12 col-sm-12">
                            <div className="product-card-2">
                                <p className="product-card-heading">Machine Parts & Equipment</p>
                                <br></br>
                                <p className="product-card-content">Have you built your own equipment
                                and need parts to be built cheaper
                                and faster.</p>
                                <p className="product-card-content">
                                We got you covered with our global team
                                with the ability to build and deliver
                                quickly.
                                </p>
                            </div>
                        </div>

                        <div className="col-xxl-4 col-xl-4 col-md-12 col-xs-12 col-sm-12">
                            <div className="product-card-2">
                                <p className="product-card-heading">Industrial 4.0</p>
                                <br></br>
                                <p className="product-card-content">Whether you are looking to build physical objects
                                 such as IoT, AR, sensors, devices, machines or robotics - we have you covered.</p>
                                <p className="product-card-content">
                                We have large ecosystem of
                                manufacturing partners using modern 
                                technology to build specific requirement at scale.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUs />
            </FadeIn>
        </div>
    );
};

export default Home;